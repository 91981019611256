    // Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

$white:    #ffffff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:    #000000;

// fusv-disable
$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900
);
// fusv-enable

$blue:    #7267EF ;  // change
$indigo:  #6610f2 ;
$purple:  #6f42c1 ;
$pink:    #e83e8c ;
$red:     #EA4D4D ;  // change
$orange:  #fd7e14 ;
$yellow:  #ffa21d ;  // change
$green:   #17C666 ;  // change
$teal:    #20c997 ;
$cyan:    #3ec9d6 ;  // change

// scss-docs-start colors-map
$colors: (
  "blue":       $blue,
  "indigo":     $indigo,
  "purple":     $purple,
  "pink":       $pink,
  "red":        $red,
  "orange":     $orange,
  "yellow":     $yellow,
  "green":      $green,
  "teal":       $teal,
  "cyan":       $cyan,
  "white":      $white,
  "gray":       $gray-600,
  "gray-dark":  $gray-800
);
// scss-docs-end colors-map

$primary:       $blue;  // change
$secondary:     $gray-600;  // change
$success:       $green;  // change
$info:          $cyan;  // change
$warning:       $yellow;  // change
$danger:        $red;  // change
$light:         $gray-100;  // change
$dark:          #1c232f;  // change

// scss-docs-start theme-colors-map
$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark
);
// scss-docs-end theme-colors-map

// Set a specific jump point for requesting color jumps
$theme-color-interval: 8% ;

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio:   1.95;

// Customize the light and dark text colors for use in our color contrast function.
$color-contrast-dark:      $gray-900 ;
$color-contrast-light:     $white ;

// fusv-disable
$blue-100: tint-color($blue, 8) ;
$blue-200: tint-color($blue, 6) ;
$blue-300: tint-color($blue, 4) ;
$blue-400: tint-color($blue, 2) ;
$blue-500: $blue ;
$blue-600: shade-color($blue, 2) ;
$blue-700: shade-color($blue, 4) ;
$blue-800: shade-color($blue, 6) ;
$blue-900: shade-color($blue, 8) ;

$indigo-100: tint-color($indigo, 8) ;
$indigo-200: tint-color($indigo, 6) ;
$indigo-300: tint-color($indigo, 4) ;
$indigo-400: tint-color($indigo, 2) ;
$indigo-500: $indigo;
$indigo-600: shade-color($indigo, 2) ;
$indigo-700: shade-color($indigo, 4) ;
$indigo-800: shade-color($indigo, 6) ;
$indigo-900: shade-color($indigo, 8) ;

$purple-100: tint-color($purple, 8) ;
$purple-200: tint-color($purple, 6) ;
$purple-300: tint-color($purple, 4) ;
$purple-400: tint-color($purple, 2) ;
$purple-500: $purple ;
$purple-600: shade-color($purple, 2) ;
$purple-700: shade-color($purple, 4) ;
$purple-800: shade-color($purple, 6) ;
$purple-900: shade-color($purple, 8) ;

$pink-100: tint-color($pink, 8) ;
$pink-200: tint-color($pink, 6) ;
$pink-300: tint-color($pink, 4) ;
$pink-400: tint-color($pink, 2) ;
$pink-500: $pink ;
$pink-600: shade-color($pink, 2) ;
$pink-700: shade-color($pink, 4) ;
$pink-800: shade-color($pink, 6) ;
$pink-900: shade-color($pink, 8) ;

$red-100: tint-color($red, 8) ;
$red-200: tint-color($red, 6) ;
$red-300: tint-color($red, 4) ;
$red-400: tint-color($red, 2) ;
$red-500: $red ;
$red-600: shade-color($red, 2) ;
$red-700: shade-color($red, 4) ;
$red-800: shade-color($red, 6) ;
$red-900: shade-color($red, 8) ;

$orange-100: tint-color($orange, 8) ;
$orange-200: tint-color($orange, 6) ;
$orange-300: tint-color($orange, 4) ;
$orange-400: tint-color($orange, 2) ;
$orange-500: $orange ;
$orange-600: shade-color($orange, 2) ;
$orange-700: shade-color($orange, 4) ;
$orange-800: shade-color($orange, 6) ;
$orange-900: shade-color($orange, 8) ;

$yellow-100: tint-color($yellow, 8) ;
$yellow-200: tint-color($yellow, 6) ;
$yellow-300: tint-color($yellow, 4) ;
$yellow-400: tint-color($yellow, 2) ;
$yellow-500: $yellow ;
$yellow-600: shade-color($yellow, 2) ;
$yellow-700: shade-color($yellow, 4) ;
$yellow-800: shade-color($yellow, 6) ;
$yellow-900: shade-color($yellow, 8) ;

$green-100: tint-color($green, 8) ;
$green-200: tint-color($green, 6) ;
$green-300: tint-color($green, 4) ;
$green-400: tint-color($green, 2) ;
$green-500: $green;
$green-600: shade-color($green, 2) ;
$green-700: shade-color($green, 4) ;
$green-800: shade-color($green, 6) ;
$green-900: shade-color($green, 8) ;

$teal-100: tint-color($teal, 8) ;
$teal-200: tint-color($teal, 6) ;
$teal-300: tint-color($teal, 4) ;
$teal-400: tint-color($teal, 2) ;
$teal-500: $teal ;
$teal-600: shade-color($teal, 2) ;
$teal-700: shade-color($teal, 4) ;
$teal-800: shade-color($teal, 6) ;
$teal-900: shade-color($teal, 8) ;

$cyan-100: tint-color($cyan, 8) ;
$cyan-200: tint-color($cyan, 6) ;
$cyan-300: tint-color($cyan, 4) ;
$cyan-400: tint-color($cyan, 2) ;
$cyan-500: $cyan ;
$cyan-600: shade-color($cyan, 2) ;
$cyan-700: shade-color($cyan, 4) ;
$cyan-800: shade-color($cyan, 6) ;
$cyan-900: shade-color($cyan, 8) ;
// fusv-enable

// Characters which are escaped by the escape-svg function
$escaped-characters: (
  ("<","%3c"),
  (">","%3e"),
  ("#","%23"),
  ("(","%28"),
  (")","%29"),
);

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-caret:                true;
$enable-rounded:              true;
$enable-shadows:              false;
$enable-gradients:            false;
$enable-transitions:          true;
$enable-reduced-motion:       true;
$enable-grid-classes:         true;
$enable-button-pointers:      true;
$enable-rfs:                  true;
$enable-validation-icons:     true;
$enable-negative-margins:     true;
$enable-deprecation-messages: true;
$enable-important-utilities:  true;

// Gradient
//
// The gradient which is added to components if `$enable-gradients` is `true`
// This gradient is also added to elements with `.bg-gradient`
$gradient: linear-gradient(180deg, rgba($white, .15), rgba($white, 0)) ;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer / 4,
  2: $spacer / 2,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
);

$negative-spacers: if($enable-negative-margins, negativify-map($spacers), null);


// Body
//
// Settings for the `<body>` element.
$body-bg:                   #f0f2f8; // change
$body-color:                #293240; // change
$body-text-align:           null;


// Links
//
// Style anchor elements.

$link-color:                              $primary;
$link-decoration:                         none ;
$link-hover-color:                        $link-color; // change
$link-hover-decoration:                   underline ;

// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 15% ;

$stretched-link-pseudo-element:           after;
$stretched-link-z-index:                  1;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom:   1rem ;


// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

// scss-docs-start grid-breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
) ;
// scss-docs-end grid-breakpoints

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");


// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

// scss-docs-start container-max-widths
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px
) ;
// scss-docs-end container-max-widths

@include _assert-ascending($container-max-widths, "$container-max-widths");


// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns:                12 ;
$grid-gutter-width:           1.5rem ;
$grid-row-columns:            6 ;


// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg:              1.5 ;
$line-height-sm:              1.5 ;

$border-width:                1px ;
$border-color:                #f1f1f1; // change

$border-radius:               4px; // change
$border-radius-lg:            6px; // change
$border-radius-sm:            2px; // change

$rounded-pill:                50rem ;

$box-shadow-sm:               0 .125rem .25rem rgba($black, .075) ;
$box-shadow:                  0 .5rem 1rem rgba($black, .15) ;
$box-shadow-lg:               0 1rem 3rem rgba($black, .175) ;
$box-shadow-inset:            inset 0 1px 2px rgba($black, .075);

$component-active-color:      $white ;
$component-active-bg:         $primary ;

$caret-width:                 .3em ;
$caret-vertical-align:        $caret-width * .85 ;
$caret-spacing:               $caret-width * .85 ;

$transition-base:             all .2s ease-in-out ;
$transition-fade:             opacity .15s linear ;
$transition-collapse:         height .35s ease ;

// scss-docs-start embed-responsive-aspect-ratios
$embed-responsive-aspect-ratios: (
  "21by9": (
    x: 21,
    y: 9
  ),
  "16by9": (
    x: 16,
    y: 9
  ),
  "4by3": (
    x: 4,
    y: 3
  ),
  "1by1": (
    x: 1,
    y: 1
  )
) ;
// scss-docs-end embed-responsive-aspect-ratios

// Typography
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif:      'Inter', sans-serif; // change
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace ;
$font-family-base:            $font-family-sans-serif ;
// stylelint-enable value-keyword-case


$font-size-root:              null ;
$font-size-base:              0.875rem ;
$font-size-sm:                $font-size-base * .875 ;
$font-size-lg:                $font-size-base * 1.25 ;
$font-weight-lighter:         lighter ;
$font-weight-light:           300 ;
$font-weight-normal:          400 ;
$font-weight-bold:            700 ;
$font-weight-bolder:          bolder ;

$font-weight-base:            $font-weight-normal ;
$line-height-base:            1.5 ;
$line-height-sm:              1.25 ;
$line-height-lg:              2 ;

$h1-font-size:                36px; // change
$h2-font-size:                28px; // change
$h3-font-size:                24px; // change
$h4-font-size:                20px; // change
$h5-font-size:                16px; // change
$h6-font-size:                15px; // change

$headings-margin-bottom:      $spacer / 2 ;
$headings-font-family:        null ;
$headings-font-style:         null ;
$headings-font-weight:        500 ;
$headings-line-height:        1.2 ;
$headings-color:              #060606;

// scss-docs-start display-headings
$display-font-sizes: (
  1: 5rem,
  2: 4.5rem,
  3: 4rem,
  4: 3.5rem,
  5: 3rem,
  6: 2.5rem
) ;

$display-font-weight: 300 ;
$display-line-height: $headings-line-height ;
// scss-docs-end display-headings

$lead-font-size:              $font-size-base * 1.25 ;
$lead-font-weight:            300 ;

$small-font-size:             80% ;

$sub-sup-font-size:           .75em ;

$text-muted:                  $gray-600 ;

$initialism-font-size:        $small-font-size ;
$blockquote-margin-y:         $spacer;
$blockquote-font-size:        $font-size-base * 1.25;
$blockquote-footer-color:     $gray-600;
$blockquote-footer-font-size: $small-font-size;

$hr-margin-y:                 $spacer ;
$hr-color:                    inherit ;
$hr-height:                   $border-width ;
$hr-opacity:                  .25 ;

$legend-margin-bottom:        .5rem ;
$legend-font-size:            1.5rem ;
$legend-font-weight:          null ;

$mark-padding:                .2em ;

$dt-font-weight:              $font-weight-bold ;


$nested-kbd-font-weight:      $font-weight-bold ;

$list-inline-padding:         .5rem ;

$mark-bg:                     #fcf8e3 ;
// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.
// scss-docs-start table-variables
$table-cell-padding-y:        0.9rem ;
$table-cell-padding-x:        0.75rem ;
$table-cell-padding-y-sm:     .3rem ;
$table-cell-padding-x-sm:     .3rem ;

$table-cell-vertical-align:   top ;

$table-color:                 $body-color ;
$table-bg:                    transparent ;

$table-th-font-weight:        null ;
$table-striped-color:         $table-color ;
$table-striped-bg-factor:     .05 ;
$table-striped-bg:            rgba($black, $table-striped-bg-factor) ;

$table-active-color:          $table-color ;
$table-active-bg-factor:      .1 ;
$table-active-bg:             rgba($black, $table-active-bg-factor) ;

$table-hover-color:           $table-color ;
$table-hover-bg-factor:       .02 ;
$table-hover-bg:              rgba($primary, $table-hover-bg-factor) ;

$table-border-factor:         .1 ;
$table-border-width:          $border-width ;
$table-border-color:          $border-color ;

$table-striped-order:         odd ;

$table-group-seperator-color: currentColor ;
$table-caption-color:         $text-muted ;

$table-bg-level:              -9 ;

$table-variants: (
  "primary":    scale-color($primary, $table-bg-level),
  "secondary":  scale-color($secondary, $table-bg-level),
  "success":    scale-color($success, $table-bg-level),
  "info":       scale-color($info, $table-bg-level),
  "warning":    scale-color($warning, $table-bg-level),
  "danger":     scale-color($danger, $table-bg-level),
  "light":      $light,
  "dark":       $dark,
) ;
// scss-docs-end table-variables


// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.



$input-btn-padding-y:         0.575rem;
$input-btn-padding-x:         1.3rem;
$input-btn-font-family:       null ;
$input-btn-font-size:         0.875rem ;
$input-btn-line-height:       $line-height-base ;

$input-btn-focus-width:         .2rem ;
$input-btn-focus-color-opacity: .25;
$input-btn-focus-color:         rgba($component-active-bg, $input-btn-focus-color-opacity) ;
$input-btn-focus-box-shadow:    0 0 0 $input-btn-focus-width $input-btn-focus-color ;

$input-btn-padding-y-sm:      .25rem ;
$input-btn-padding-x-sm:      .5rem ;
$input-btn-font-size-sm:      $font-size-sm ;

$input-btn-padding-y-lg:      .5rem ;
$input-btn-padding-x-lg:      1rem ;
$input-btn-font-size-lg:      $font-size-lg ;

$input-btn-border-width:      2px ;


// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y:               $input-btn-padding-y;
$btn-padding-x:               $input-btn-padding-x;
$btn-font-family:             $input-btn-font-family ;
$btn-font-size:               $input-btn-font-size ;
$btn-line-height:             $input-btn-line-height ;
$btn-white-space:             null ; // Set to `nowrap` to prevent text wrapping

$btn-padding-y-sm:            $input-btn-padding-y-sm ;
$btn-padding-x-sm:            $input-btn-padding-x-sm ;
$btn-font-size-sm:            $input-btn-font-size-sm ;

$btn-padding-y-lg:            $input-btn-padding-y-lg;
$btn-padding-x-lg:            $input-btn-padding-x-lg ;
$btn-font-size-lg:            $input-btn-font-size-lg ;

$btn-border-width:            $input-btn-border-width ;

$btn-font-weight:             500 ;
$btn-box-shadow:              inset 0 1px 0 rgba($white, .15), 0 1px 1px rgba($black, .075) ;
$btn-focus-width:             $input-btn-focus-width ;
$btn-focus-box-shadow:        $input-btn-focus-box-shadow ;
$btn-disabled-opacity:        .65 ;
$btn-active-box-shadow:       inset 0 3px 5px rgba($black, .125) ;

$btn-link-color:              $link-color ;
$btn-link-hover-color:        $link-hover-color ;
$btn-link-disabled-color:     $gray-600 ;

$btn-block-spacing-y:         .5rem ;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:           4px ;
$btn-border-radius-sm:        2px ;
$btn-border-radius-lg:        6px ;

$btn-transition:              color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out ;


// Forms

$form-text-margin-top:                  .25rem ;
$form-text-font-size:                   $small-font-size ;
$form-text-font-style:                  null ;
$form-text-font-weight:                 null ;
$form-text-color:                       $text-muted ;

$form-label-margin-bottom:              .5rem ;
$form-label-font-size:                  null ;
$form-label-font-style:                 null ;
$form-label-font-weight:                null ;
$form-label-color:                      null ;

$input-padding-y:                       $input-btn-padding-y ;
$input-padding-x:                       1rem ;
$input-font-family:                     $input-btn-font-family ;
$input-font-size:                       $input-btn-font-size ;
$input-font-weight:                     $font-weight-base ;
$input-line-height:                     $input-btn-line-height ;

$input-padding-y-sm:                    $input-btn-padding-y-sm ;
$input-padding-x-sm:                    $input-btn-padding-x-sm ;
$input-font-size-sm:                    $input-btn-font-size-sm ;

$input-padding-y-lg:                    $input-btn-padding-y-lg ;
$input-padding-x-lg:                    $input-btn-padding-x-lg ;
$input-font-size-lg:                    $input-btn-font-size-lg ;

$input-bg:                              $white ;
$input-disabled-bg:                     $gray-200 ;
$input-disabled-border-color:           null ;

$input-color:                           $body-color ;
$input-border-color:                    $gray-400 ;
$input-border-width:                    $input-btn-border-width;
$input-box-shadow:                      inset 0 1px 1px rgba($black, .075) ;

$input-border-radius:                   $border-radius ;
$input-border-radius-sm:                $border-radius-sm ;
$input-border-radius-lg:                $border-radius-lg ;

$input-focus-bg:                        $input-bg ;
$input-focus-border-color:              $primary ;
$input-focus-color:                     $input-color ;
$input-focus-width:                     $input-btn-focus-width ;
$input-focus-box-shadow:                mone ;

$input-placeholder-color:               $gray-600 ;
$input-plaintext-color:                 $body-color ;

$input-height-border:                   $input-border-width * 2 ;

$input-height-inner:                    add($input-line-height * 1em, $input-padding-y * 2) ;
$input-height-inner-half:               add($input-line-height * .5em, $input-padding-y) ;
$input-height-inner-quarter:            add($input-line-height * .25em, $input-padding-y / 2) ;

$input-height:                          add($input-line-height * 1em, add($input-padding-y * 2, $input-height-border, false)) ;
$input-height-sm:                       add($input-line-height * 1em, add($input-padding-y-sm * 2, $input-height-border, false)) ;
$input-height-lg:                       add($input-line-height * 1em, add($input-padding-y-lg * 2, $input-height-border, false)) ;

$input-transition:                      border-color .15s ease-in-out, box-shadow .15s ease-in-out ;


$form-check-input-width:                  1.25em ;
$form-check-min-height:                   $font-size-base * $line-height-base ;
$form-check-padding-left:                 $form-check-input-width + .5em ;
$form-check-margin-bottom:                .125rem ;
$form-check-label-color:                  null ;
$form-check-label-cursor:                 null ;
$form-check-transition:                   background-color .15s ease-in-out, background-position .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out ;

$form-check-input-active-filter:          brightness(90%) ;

$form-check-input-bg:                     $input-bg ;
$form-check-input-border:                 1px solid rgba(0, 0, 0, .25) ;
$form-check-input-border-radius:          .25em ;
$form-check-radio-border-radius:          50% ;
$form-check-input-focus-border:           $input-focus-border-color ;
$form-check-input-focus-box-shadow:       $input-btn-focus-box-shadow ;

$form-check-input-checked-color:          $component-active-color ;
$form-check-input-checked-bg-color:       $component-active-bg ;
$form-check-input-checked-border-color:   $form-check-input-checked-bg-color ;
$form-check-input-checked-bg-image:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-checked-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/></svg>") ;
$form-check-radio-checked-bg-image:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{$form-check-input-checked-color}'/></svg>") ;

$form-check-input-indeterminate-color:          $component-active-color ;
$form-check-input-indeterminate-bg-color:       $component-active-bg ;
$form-check-input-indeterminate-border-color:   $form-check-input-indeterminate-bg-color;
$form-check-input-indeterminate-bg-image:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{$form-check-input-indeterminate-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/></svg>") ;

$form-switch-color:               rgba(0, 0, 0, .25) ;
$form-switch-width:               2em ;
$form-switch-padding-left:        $form-switch-width + .5em ;
$form-switch-bg-image:            url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-color}'/></svg>") ;
$form-switch-border-radius:       $form-switch-width ;

$form-switch-focus-color:         $input-focus-border-color ;
$form-switch-focus-bg-image:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-focus-color}'/></svg>") ;

$form-switch-checked-color:       $component-active-color ;
$form-switch-checked-bg-image:    url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-switch-checked-color}'/></svg>") ;
$form-switch-checked-bg-position: right center ;

$form-check-inline-margin-right:        1rem ;

$input-group-addon-padding-y:           $input-padding-y;
$input-group-addon-padding-x:           $input-padding-x;
$input-group-addon-font-weight:         $input-font-weight;
$input-group-addon-color:               $input-color ;
$input-group-addon-bg:                  $body-bg ;
$input-group-addon-border-color:        $input-border-color ;


$form-select-padding-y:             $input-padding-y ;
$form-select-padding-x:             $input-padding-x ;
$form-select-font-family:           $input-font-family ;
$form-select-font-size:             $input-font-size ;
$form-select-height:                $input-height ;
$form-select-indicator-padding:     1rem ; // Extra padding to account for the presence of the background-image based indicator
$form-select-font-weight:           $input-font-weight ;
$form-select-line-height:           $input-line-height ;
$form-select-color:                 $input-color ;
$form-select-disabled-color:        $gray-600 ;
$form-select-bg:                    $input-bg ;
$form-select-disabled-bg:           $gray-200 ;
$form-select-disabled-border-color: $input-disabled-border-color ;
$form-select-bg-position:           right $form-select-padding-x center ;
$form-select-bg-size:               16px 12px ; // In pixels because image dimensions
$form-select-indicator-color:       $gray-800 ;
$form-select-indicator:             url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{$form-select-indicator-color}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>") ;

$form-select-feedback-icon-padding-right: add(1em * .75, (2 * $form-select-padding-y * .75) + $form-select-padding-x + $form-select-indicator-padding) ;
$form-select-feedback-icon-position:      center right ($form-select-padding-x + $form-select-indicator-padding) ;
$form-select-feedback-icon-size:          $input-height-inner-half $input-height-inner-half ;

$form-select-border-width:        $input-border-width ;
$form-select-border-color:        $input-border-color ;
$form-select-border-radius:       $border-radius ;
$form-select-box-shadow:          $box-shadow-inset ;

$form-select-focus-border-color:  $input-focus-border-color ;
$form-select-focus-width:         $input-focus-width ;
$form-select-focus-box-shadow:    0 0 0 $form-select-focus-width $input-btn-focus-color ;

$form-select-padding-y-sm:        $input-padding-y-sm ;
$form-select-padding-x-sm:        $input-padding-x-sm ;
$form-select-font-size-sm:        $input-font-size-sm ;
$form-select-height-sm:           $input-height-sm ;

$form-select-padding-y-lg:        $input-padding-y-lg ;
$form-select-padding-x-lg:        $input-padding-x-lg ;
$form-select-font-size-lg:        $input-font-size-lg ;
$form-select-height-lg:           $input-height-lg ;

$form-range-track-width:          100% ;
$form-range-track-height:         .5rem ;
$form-range-track-cursor:         pointer ;
$form-range-track-bg:             $body-bg;
$form-range-track-border-radius:  1rem ;
$form-range-track-box-shadow:     $box-shadow-inset ;

$form-range-thumb-width:                   1rem ;
$form-range-thumb-height:                  $form-range-thumb-width ;
$form-range-thumb-bg:                      $component-active-bg ;
$form-range-thumb-border:                  0 ;
$form-range-thumb-border-radius:           1rem ;
$form-range-thumb-box-shadow:              0 .1rem .25rem rgba($black, .1) ;
$form-range-thumb-focus-box-shadow:        0 0 0 1px $body-bg, $input-focus-box-shadow ;
$form-range-thumb-focus-box-shadow-width:  $input-focus-width ; // For focus box shadow issue in Edge
$form-range-thumb-active-bg:               lighten($component-active-bg, 35%) ;
$form-range-thumb-disabled-bg:             $gray-500 ;
$form-range-thumb-transition:              background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out ;

$form-file-height:                $input-height ;
$form-file-focus-border-color:    $input-focus-border-color ;
$form-file-focus-box-shadow:      $input-focus-box-shadow ;
$form-file-disabled-bg:           $input-disabled-bg ;
$form-file-disabled-border-color: $input-disabled-border-color ;

$form-file-padding-y:             $input-padding-y ;
$form-file-padding-x:             $input-padding-x ;
$form-file-line-height:           $input-line-height ;
$form-file-font-family:           $input-font-family ;
$form-file-font-weight:           $input-font-weight ;
$form-file-color:                 $input-color ;
$form-file-bg:                    $input-bg ;
$form-file-border-width:          $input-border-width ;
$form-file-border-color:          $input-border-color ;
$form-file-border-radius:         $input-border-radius ;
$form-file-box-shadow:            $input-box-shadow ;
$form-file-button-color:          $form-file-color ;
$form-file-button-bg:             $input-group-addon-bg ;

$form-file-padding-y-sm:          $input-padding-y-sm ;
$form-file-padding-x-sm:          $input-padding-x-sm ;
$form-file-font-size-sm:          $input-font-size-sm ;
$form-file-height-sm:             $input-height-sm ;

$form-file-padding-y-lg:          $input-padding-y-lg ;
$form-file-padding-x-lg:          $input-padding-x-lg ;
$form-file-font-size-lg:          $input-font-size-lg ;
$form-file-height-lg:             $input-height-lg ;


// Form validation

$form-feedback-margin-top:          $form-text-margin-top ;
$form-feedback-font-size:           $small-font-size ;
$form-feedback-font-style:          $form-text-font-style;
$form-feedback-valid-color:         $success ;
$form-feedback-invalid-color:       $danger ;

$form-feedback-icon-valid-color:    $form-feedback-valid-color ;
$form-feedback-icon-valid:          str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e"), "#", "%23") ;
$form-feedback-icon-invalid-color:  $form-feedback-invalid-color ;
$form-feedback-icon-invalid:        str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='#{$form-feedback-icon-invalid-color}' viewBox='-2 -2 7 7'%3e%3cpath stroke='#{$form-feedback-icon-invalid-color}' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E"), "#", "%23") ;

// scss-docs-start form-validation-states
$form-validation-states: (
  "valid": (
    "color": $form-feedback-valid-color,
    "icon": $form-feedback-icon-valid
  ),
  "invalid": (
    "color": $form-feedback-invalid-color,
    "icon": $form-feedback-icon-invalid
  )
) ;
// scss-docs-end form-validation-states

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

// scss-docs-start zindex-stack
$zindex-dropdown:                   1000 ;
$zindex-sticky:                     1020 ;
$zindex-fixed:                      1030 ;
$zindex-modal-backdrop:             1040 ;
$zindex-modal:                      1050 ;
$zindex-popover:                    1060 ;
$zindex-tooltip:                    1070 ;
// scss-docs-end zindex-stack


// Navs

$nav-link-padding-y:                .5rem;
$nav-link-padding-x:                1rem;
$nav-link-font-size:                null;
$nav-link-font-weight:              null;
$nav-link-color:                    null;
$nav-link-hover-color:              null;
$nav-link-transition:               color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
$nav-link-disabled-color:           $gray-600;

$nav-tabs-border-color:             $gray-300;
$nav-tabs-border-width:             $border-width ;
$nav-tabs-border-radius:            $border-radius ;
$nav-tabs-link-hover-border-color:  $gray-200 $gray-200 $nav-tabs-border-color ;
$nav-tabs-link-active-color:        $gray-700 ;
$nav-tabs-link-active-bg:           $white;// change
$nav-tabs-link-active-border-color: $gray-300 $gray-300 $nav-tabs-link-active-bg ;

$nav-pills-border-radius:           $border-radius ;
$nav-pills-link-active-color:       $component-active-color ;
$nav-pills-link-active-bg:          $component-active-bg ;



// Navbar

$navbar-padding-y:                  $spacer / 2 ;
$navbar-padding-x:                  null ;

$navbar-nav-link-padding-x:         .5rem ;

$navbar-brand-font-size:            $font-size-lg ;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
$nav-link-height:                   $font-size-base * $line-height-base + $nav-link-padding-y * 2 ;
$navbar-brand-height:               $navbar-brand-font-size * $line-height-base ;
$navbar-brand-padding-y:            ($nav-link-height - $navbar-brand-height) / 2 ;
$navbar-brand-margin-right:         1rem ;

$navbar-toggler-padding-y:          .25rem ;
$navbar-toggler-padding-x:          .75rem ;
$navbar-toggler-font-size:          $font-size-lg ;
$navbar-toggler-border-radius:      $btn-border-radius ;
$navbar-toggler-focus-width:        $btn-focus-width ;
$navbar-toggler-transition:         box-shadow .15s ease-in-out ;

$navbar-dark-color:                 rgba($white, .55) ;
$navbar-dark-hover-color:           rgba($white, .75) ;
$navbar-dark-active-color:          $white ;
$navbar-dark-disabled-color:        rgba($white, .25) ;
$navbar-dark-toggler-icon-bg:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") ;
$navbar-dark-toggler-border-color:  rgba($white, .1) ;

$navbar-light-color:                rgba($black, .55) ;
$navbar-light-hover-color:          rgba($black, .7) ;
$navbar-light-active-color:         rgba($black, .9) ;
$navbar-light-disabled-color:       rgba($black, .3) ;
$navbar-light-toggler-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") ;
$navbar-light-toggler-border-color: rgba($black, .1) ;

$navbar-light-brand-color:                $navbar-light-active-color ;
$navbar-light-brand-hover-color:          $navbar-light-active-color ;
$navbar-dark-brand-color:                 $navbar-dark-active-color ;
$navbar-dark-brand-hover-color:           $navbar-dark-active-color ;


// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-min-width:                12rem;
$dropdown-padding-y:                .5rem;
$dropdown-spacer:                   .125rem;
$dropdown-font-size:                $font-size-base;
$dropdown-color:                    $body-color;
$dropdown-bg:                       $white ;
$dropdown-border-color:             rgba($black, .15) ;
$dropdown-border-radius:            $border-radius ;
$dropdown-border-width:             0;
$dropdown-inner-border-radius:      subtract($dropdown-border-radius, $dropdown-border-width) ;
$dropdown-divider-bg:               $gray-200 ;
$dropdown-divider-margin-y:         $spacer / 2 ;
$dropdown-box-shadow:               0 .5rem 1rem rgba($black, .175) ;

$dropdown-link-color:               $body-color ;
$dropdown-link-hover-color:         $primary;
$dropdown-link-hover-bg:            scale-color($primary, -80%);
$dropdown-link-active-color:        $primary ;
$dropdown-link-active-bg:           scale-color($primary, -80%) ;

$dropdown-link-disabled-color:      $gray-600 ;

$dropdown-item-padding-y:           .65rem ;
$dropdown-item-padding-x:           1.5rem ;

$dropdown-header-color:             $gray-600 ;
$dropdown-header-padding:           $dropdown-padding-y $dropdown-item-padding-x ;

$dropdown-dark-color:               $gray-300;
$dropdown-dark-bg:                  $gray-800;
$dropdown-dark-border-color:        $dropdown-border-color;
$dropdown-dark-divider-bg:          $dropdown-divider-bg;
$dropdown-dark-box-shadow:          null;
$dropdown-dark-link-color:          $dropdown-dark-color;
$dropdown-dark-link-hover-color:    $white;
$dropdown-dark-link-hover-bg:       rgba($white, .15);
$dropdown-dark-link-active-color:   $dropdown-link-active-color;
$dropdown-dark-link-active-bg:      $dropdown-link-active-bg;
$dropdown-dark-link-disabled-color: $gray-500;
$dropdown-dark-header-color:        $gray-500;

// Pagination

$pagination-padding-y:              .375rem;
$pagination-padding-x:              .75rem;
$pagination-padding-y-sm:           .25rem;
$pagination-padding-x-sm:           .5rem;
$pagination-padding-y-lg:           .75rem;
$pagination-padding-x-lg:           1.5rem;

$pagination-color:                  $primary;
$pagination-bg:                     $white;
$pagination-border-width:           $border-width;
$pagination-border-radius:          $border-radius;
$pagination-margin-left:            -$pagination-border-width;
$pagination-border-color:           $gray-300;

$pagination-focus-color:            $link-hover-color ;
$pagination-focus-bg:               $gray-200 ;
$pagination-focus-box-shadow:       $input-btn-focus-box-shadow;
$pagination-focus-outline:          0;

$pagination-hover-color:            $link-hover-color;
$pagination-hover-bg:               $body-bg;
$pagination-hover-border-color:     $gray-300;

$pagination-active-color:           $component-active-color;
$pagination-active-bg:              $component-active-bg;
$pagination-active-border-color:    $pagination-active-bg;

$pagination-disabled-color:         $gray-600;
$pagination-disabled-bg:            $white;
$pagination-disabled-border-color:  $gray-300;


// Cards
$card-spacer-y:                     25px; // change
$card-spacer-x:                     25px;  // change
$card-title-spacer-y:               $spacer / 2 ;
$card-border-width:                 0px; // change
$card-border-radius:                $border-radius ;
$card-border-color:                 rgba($black, .125) ;
$card-inner-border-radius:          calc(#{$card-border-radius} - #{$card-border-width}) ;
$card-cap-padding-y:                25px;
$card-cap-padding-x:                25px;
$card-cap-bg:                       transparent;
$card-cap-color:                    null ;
$card-height:                       null ;
$card-color:                        null ;
$card-bg:                           $white ;

$card-img-overlay-padding:          1.25rem ;

$card-group-margin:                 $grid-gutter-width / 2 ;


// Tooltips

$tooltip-font-size:                 $font-size-sm ;
$tooltip-max-width:                 200px ;
$tooltip-color:                     $white ;
$tooltip-bg:                        $black ;
$tooltip-border-radius:             $border-radius ;
$tooltip-opacity:                   .9 ;
$tooltip-padding-y:                 .25rem ;
$tooltip-padding-x:                 .5rem ;
$tooltip-margin:                    0 ;

$tooltip-arrow-width:               .8rem ;
$tooltip-arrow-height:              .4rem ;
$tooltip-arrow-color:               $tooltip-bg ;

// Form tooltips must come after regular tooltips
$form-feedback-tooltip-padding-y:     $tooltip-padding-y ;
$form-feedback-tooltip-padding-x:     $tooltip-padding-x ;
$form-feedback-tooltip-font-size:     $tooltip-font-size ;
$form-feedback-tooltip-line-height:   $line-height-base ;
$form-feedback-tooltip-opacity:       $tooltip-opacity ;
$form-feedback-tooltip-border-radius: $tooltip-border-radius ;


// Popovers

$popover-font-size:                 $font-size-sm ;
$popover-bg:                        $white ;
$popover-max-width:                 276px ;
$popover-border-width:              $border-width ;
$popover-border-color:              rgba($black, .2) ;
$popover-border-radius:             $border-radius-lg ;
$popover-inner-border-radius:       subtract($popover-border-radius, $popover-border-width) ;
$popover-box-shadow:                $box-shadow ;

$popover-header-bg:                 darken($popover-bg, 3%) ;
$popover-header-color:              $headings-color ;
$popover-header-padding-y:          .5rem ;
$popover-header-padding-x:          .75rem ;

$popover-body-color:                $body-color ;
$popover-body-padding-y:            $spacer ;
$popover-body-padding-x:            $spacer ;

$popover-arrow-width:               1rem ;
$popover-arrow-height:              .5rem ;
$popover-arrow-color:               $popover-bg ;

$popover-arrow-outer-color:         fade-in($popover-border-color, .05) ;


// Toasts

$toast-max-width:                   350px ;
$toast-padding-x:                   .75rem ;
$toast-padding-y:                   .25rem ;
$toast-font-size:                   .875rem ;
$toast-color:                       null ;
$toast-background-color:            rgba($white, .85) ;
$toast-border-width:                1px ;
$toast-border-color:                rgba(0, 0, 0, .1) ;
$toast-border-radius:               .25rem ;
$toast-box-shadow:                  0 .25rem .75rem rgba($black, .1) ;

$toast-header-color:                $gray-600 ;
$toast-header-background-color:     rgba($white, .85) ;
$toast-header-border-color:         rgba(0, 0, 0, .05) ;


// Badges
$badge-font-size:                   .75em;
$badge-font-weight:                 500;
$badge-color:                       $white;
$badge-padding-y:                   .35em;
$badge-padding-x:                   .5em;
$badge-border-radius:               2px;


// Modals

// Padding applied to the modal body
$modal-inner-padding:               1.25rem; // change

// Margin between elements in footer, must be lower than or equal to 2 * $modal-inner-padding
$modal-footer-margin-between:       .5rem;
$modal-dialog-margin:               .5rem ;
$modal-dialog-margin-y-sm-up:       1.75rem ;

$modal-title-line-height:           $line-height-base ;

$modal-content-color:               null ;
$modal-content-bg:                  $white ;
$modal-content-border-color:        rgba($black, .2) ;
$modal-content-border-width:        $border-width ;
$modal-content-border-radius:       $border-radius-lg ;
$modal-content-inner-border-radius: subtract($modal-content-border-radius, $modal-content-border-width) ;
$modal-content-box-shadow-xs:       0 .25rem .5rem rgba($black, .5) ;
$modal-content-box-shadow-sm-up:    0 .5rem 1rem rgba($black, .5) ;

$modal-backdrop-bg:                 $black ;
$modal-backdrop-opacity:            .5 ;
$modal-header-border-color:         $border-color ;
$modal-footer-border-color:         $modal-header-border-color ;
$modal-header-border-width:         $modal-content-border-width ;
$modal-footer-border-width:         $modal-header-border-width ;
$modal-header-padding-y:            1.25rem; // change
$modal-header-padding-x:            1.5625rem; // change
$modal-header-padding:              $modal-header-padding-y $modal-header-padding-x; // change

$modal-sm:                          300px ;
$modal-md:                          500px ;
$modal-lg:                          800px ;
$modal-xl:                          1140px ;

$modal-fade-transform:              translate(0, -50px) ;
$modal-show-transform:              none ;
$modal-transition:                  transform .3s ease-out ;
$modal-scale-transform:             scale(1.02) ;


// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding-y:                   .75rem ;
$alert-padding-x:                   1.25rem ;
$alert-margin-bottom:               1rem ;
$alert-border-radius:               $border-radius ;
$alert-link-font-weight:            $font-weight-bold ;
$alert-border-width:                $border-width ;

$alert-bg-level:                    -10 ;
$alert-border-level:                -9 ;
$alert-scale-color:                 6 ;

$alert-dismissible-padding-r:       $alert-padding-x * 3 ; // 3x covers width of x plus default padding on either side

// Progress bars

$progress-height:                   1rem ;
$progress-font-size:                $font-size-base * .75 ;
$progress-bg:                       $body-bg; // change
$progress-border-radius:            $border-radius ;
$progress-box-shadow:               inset 0 .1rem .1rem rgba($black, .1) ;
$progress-bar-color:                $white ;
$progress-bar-bg:                   $primary; // change
$progress-bar-animation-timing:     1s linear infinite ;
$progress-bar-transition:           width .6s ease ;


// List group

$list-group-color:                  null ;
$list-group-bg:                     $white ;
$list-group-border-color:           $border-color ;
$list-group-border-width:           $border-width ;
$list-group-border-radius:          $border-radius ;

$list-group-item-padding-y:         $card-spacer-y /2;
$list-group-item-padding-x:         $card-spacer-x;
$list-group-item-bg-level:          -9;
$list-group-item-scale-color:       6 ;

$list-group-hover-bg:               $gray-100 ;
$list-group-active-color:           $component-active-color ;
$list-group-active-bg:              $component-active-bg ;
$list-group-active-border-color:    $list-group-active-bg ;

$list-group-disabled-color:         $gray-600 ;
$list-group-disabled-bg:            $list-group-bg ;

$list-group-action-color:           $gray-700 ;
$list-group-action-hover-color:     $list-group-action-color ;

$list-group-action-active-color:    $body-color ;
$list-group-action-active-bg:       $gray-200 ;


// Image thumbnails

$thumbnail-padding:                 .25rem ;
$thumbnail-bg:                      $body-bg ;
$thumbnail-border-width:            $border-width ;
$thumbnail-border-color:            $border-color ;
$thumbnail-border-radius:           $border-radius ;
$thumbnail-box-shadow:              0 1px 2px rgba($black, .075) ;


// Figures

$figure-caption-font-size:          90% ;
$figure-caption-color:              $gray-600 ;


// Breadcrumbs

$breadcrumb-font-size:              null ;
$breadcrumb-padding-y:              .75rem ;
$breadcrumb-padding-x:              1rem ;
$breadcrumb-item-padding:           .5rem ;

$breadcrumb-margin-bottom:          1rem ;

$breadcrumb-bg:                     $body-bg; // change
$breadcrumb-divider-color:          $gray-600;
$breadcrumb-active-color:           $gray-600;
$breadcrumb-divider:                 url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='14' height='14' stroke='#{$gray-600}' stroke-width='2' fill='none' stroke-linecap='round' stroke-linejoin='round' class='css-i6dzq1'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E");

$breadcrumb-border-radius:          $border-radius ;


// Carousel

$carousel-control-color:             $white ;
$carousel-control-width:             15% ;
$carousel-control-opacity:           .5 ;
$carousel-control-hover-opacity:     .9 ;
$carousel-control-transition:        opacity .15s ease ;

$carousel-indicator-width:           30px ;
$carousel-indicator-height:          3px ;
$carousel-indicator-hit-area-height: 10px ;
$carousel-indicator-spacer:          3px ;
$carousel-indicator-opacity:         .5 ;
$carousel-indicator-active-bg:       $white ;
$carousel-indicator-active-opacity:  1 ;
$carousel-indicator-transition:      opacity .6s ease ;

$carousel-caption-width:             70% ;
$carousel-caption-color:             $white ;
$carousel-caption-padding-y:         1.25rem ;
$carousel-caption-spacer:            1.25rem ;

$carousel-control-icon-width:        20px ;

$carousel-control-prev-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 8 8'><path d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/></svg>") ;
$carousel-control-next-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 8 8'><path d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/></svg>") ;

$carousel-transition-duration:       .6s ;
$carousel-transition:                transform $carousel-transition-duration ease-in-out ; // Define transform transition first if using multiple transitions (e.g., `transform 2s ease, opacity .5s ease-out`)


// Spinners

$spinner-width:           2rem ;
$spinner-height:          $spinner-width ;
$spinner-border-width:    .25em ;
$spinner-animation-speed: .75s ;

$spinner-width-sm:        1rem ;
$spinner-height-sm:       $spinner-width-sm ;
$spinner-border-width-sm: .2em ;


// Close

$btn-close-width:            1em ;
$btn-close-height:           $btn-close-width ;
$btn-close-padding-x:        .25em ;
$btn-close-padding-y:        $btn-close-padding-x ;
$btn-close-color:            $black ;
$btn-close-bg:               url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$btn-close-color}' viewBox='0 0 16 16'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>") ;
$btn-close-focus-shadow:     $input-btn-focus-box-shadow ;
$btn-close-opacity:          .5 ;
$btn-close-hover-opacity:    .75 ;
$btn-close-focus-opacity:    1 ;
$btn-close-disabled-opacity: .25 ;
$btn-close-white-filter:     invert(1) grayscale(100%) brightness(200%) ;

// Code

$code-font-size:                    87.5% ;
$code-color:                        $pink ;

$kbd-padding-y:                     .2rem ;
$kbd-padding-x:                     .4rem ;
$kbd-font-size:                     $code-font-size ;
$kbd-color:                         $white ;
$kbd-bg:                            $gray-900 ;

$pre-color:                         null ;
